import React from 'react';
import styled from '@emotion/styled';

import Text from '../../../Text';
import DefaultGridSection from '../../DefaultGridSection';
import CheckedListItem from '../../../CheckedListItem';
import { media } from '../../../../utils/emotion';
import routerGraphics from '../../../../assets/product/router-hero.svg';
import routerBox from '../../../../assets/product/router-box.svg';
import routerBoxMobile from '../../../../assets/product/router-box-mobile.svg';

const SpacingContainer = styled('div')`
  margin-top: 9rem;
`;

const ImageBoxContainer = styled('div')`
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 100%;
`;

const ImageBox = styled('div')`
  display: flex;
  margin-bottom: 5rem;
  background: transparent url(${routerBoxMobile}) no-repeat center center;
  background-size: contain;
  min-height: 48rem;
  ${media.tablet`
    margin-bottom: 9.6rem;
    background: transparent url(${routerBox}) no-repeat center center;
    background-size: contain;
    min-height: 27rem;
  `}
  width: 100%;
  max-width: 110rem;
`;

const DescriptionSection = () => {
  return (
    <SpacingContainer>
      <DefaultGridSection
        image={routerGraphics}
        direction="imageOnRight"
        title="Business Analysis Services"
        subtitle="Identify Business Objectives"
      >
        <Text>
          We use Design thinking to help identify business needs and determine solutions to business problems. 
          We advance enterprise adoption of digital assets and blockchain technology by showing how they can add value to your 
          bottom line. Benefit from seamless technical implementation, 
          solution delivery, system integration, and a full suite of ongoing support services.
        </Text>
        <CheckedListItem>
          Evaluates the potential of your idea
          <Text color="caption">increases the chances of successful market entry</Text>
        </CheckedListItem>
        <CheckedListItem>
          Saves your budget before and during the software development process
          <Text color="caption">
            deploy resources for the correct objective
          </Text>
        </CheckedListItem>
        <CheckedListItem>
          Strategic Blockchain Advisory
        </CheckedListItem>
      </DefaultGridSection>
      {/* <ImageBoxContainer>
        <ImageBox />
      </ImageBoxContainer> */}
    </SpacingContainer>
  );
};

DescriptionSection.propTypes = {};

export default DescriptionSection;
